<template>
  <div class="descriptions">
    <el-descriptions class="margin-top" :column="3" border>
      <el-descriptions-item
        label="填报日期 Application Time"
        label-class-name="my-label"
      >
        {{ descriptions.ApplicationTime | tableDateFrom }}
      </el-descriptions-item>
      <el-descriptions-item label="序号 No" label-class-name="my-label">
        {{ descriptions.ApplicationNumber }}
      </el-descriptions-item>
      <el-descriptions-item
        label="出国任务 Work Abroad"
        label-class-name="my-label"
      >
        {{ descriptions.WorkAbroad }}
      </el-descriptions-item>
      <el-descriptions-item
        label="出国（境）依据及 事由说明 Work Basis and Description"
        label-class-name="my-label"
      >
        {{ descriptions.WorkBasisandDescription }}
      </el-descriptions-item>
      <el-descriptions-item
        label="邀请单位 Invitation Unit"
        label-class-name="my-label"
      >
        {{ descriptions.InvitationUnit }}
      </el-descriptions-item>
      <el-descriptions-item
        label="出访国家(地区) Visit Countries (Area)"
        label-class-name="my-label"
      >
        {{ descriptions.VisitCountriesorAreas }}
      </el-descriptions-item>
      <el-descriptions-item
        label="出访时间及 停留天数(含路程)  Visit Date and days"
        label-class-name="my-label"
      >
        {{ descriptions.VisitDateanddays }}
      </el-descriptions-item>
      <el-descriptions-item
        label="联系人 Contact Person"
        label-class-name="my-label"
      >
        {{ descriptions.ContactPerson }}
      </el-descriptions-item>
      <el-descriptions-item
        label="联络电话 Phone number"
        label-class-name="my-label"
      >
        {{ descriptions.Phonenumber }}
      </el-descriptions-item>
      <el-descriptions-item label="E-mail" label-class-name="my-label">
        {{ descriptions.Email }}
      </el-descriptions-item>
    </el-descriptions>
    <div class="descriptions-title" v-if="descriptions.Details">明细</div>

    <el-table
      :data="descriptions.Details"
      v-if="descriptions.Details"
      :header-cell-style="{ color: '#909399', background: '#f5f7fa' }"
    >
      <el-table-column type="index" width="50" label="序号"> </el-table-column>
      <el-table-column prop="EmployeeName" label="中文姓名" width="120">
      </el-table-column>
      <el-table-column prop="ProjectName" label="项目名称" width="120">
      </el-table-column>

      <el-table-column prop="WorkingUnit" label="用工单位" width="80">
      </el-table-column>
      <el-table-column prop="NationalityName" label="国籍 " width="80">
      </el-table-column>
      <el-table-column prop="PassportCode" label="护照号" width="80">
      </el-table-column>
      <el-table-column prop="IQAMAID" label="暂住证号/入境号" width="100">
      </el-table-column>
      <el-table-column prop="SPONSOR" label="SPONSOR" width="100">
        <template slot-scope="scope">
          {{ scope.row.SPONSOR | SponsorFormater }}
        </template>
      </el-table-column>
    </el-table>

    <div
      class="descriptions-title"
      v-if="
        descriptions.UploadingDocument1 ||
        descriptions.UploadingDocument2 ||
        descriptions.UploadingDocument3 ||
        descriptions.UploadingDocument4 ||
        descriptions.UploadingDocument5
      "
    >
      相关附件
    </div>
    <div
      v-if="descriptions.UploadingDocument1"
      @click="download(descriptions.UploadingDocument1)"
      class="url"
    >
      {{ descriptions.UploadingDocument1 | uploadingDocumentFormater }}
    </div>
    <div
      v-if="descriptions.UploadingDocument2"
      @click="download(descriptions.UploadingDocument2)"
      class="url"
    >
      {{ descriptions.UploadingDocument2 | uploadingDocumentFormater }}
    </div>
    <div
      v-if="descriptions.UploadingDocument3"
      @click="download(descriptions.UploadingDocument3)"
      class="url"
    >
      {{ descriptions.UploadingDocument3 | uploadingDocumentFormater }}
    </div>
    <div
      v-if="descriptions.UploadingDocument4"
      @click="download(descriptions.UploadingDocument4)"
      class="url"
    >
      {{ descriptions.UploadingDocument4 | uploadingDocumentFormater }}
    </div>
    <div
      v-if="descriptions.UploadingDocument5"
      @click="download(descriptions.UploadingDocument5)"
      class="url"
    >
      {{ descriptions.UploadingDocument5 | uploadingDocumentFormater }}
    </div>
  </div>
</template>

<script>
import {
  getProjectAll,
  allPosition,
  getDepartmentAll,
  getCompanyAll,
} from "@/api/user";
export default {
  filters: {
    projectFormater(id, data) {
      const item = data.find((item) => {
        return item.ProjectID === id;
      });
      return item ? item.ProjectName : null;
    },
    positionFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.PositionsCHS : null;
    },
    departmentFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.DepartmentCHS : null;
    },
    companyFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.CompanyCHS : null;
    },
    tableDateFrom(data) {
      if (data) {
        data = data.slice(0, 10);
      }
      return data;
    },
    SponsorFormater(id) {
      const data = [
        {
          label: "SNEI",
          value: 1,
        },
        {
          label: "SEG",
          value: 2,
        },
        {
          label: "其它",
          value: 3,
        },
      ];
      const item = data.find((item) => {
        return item.value === id;
      });
      return item ? item.label : null;
    },
    employmentTypeFormater(id) {
      const data = [
        {
          value: 1,
          name: "正式职工",
        },
        {
          value: 2,
          name: "直聘",
        },
        {
          value: 3,
          name: "岗位外包",
        },
        {
          value: 4,
          name: "国内分包",
        },
        {
          value: 5,
          name: "岗位外包",
        },
        {
          value: 6,
          name: "外籍员工",
        },

        {
          value: 7,
          name: "虚拟沙化",
        },
        {
          value: 8,
          name: "常设劳务",
        },
        {
          value: 9,
          name: "当地分包",
        },
        {
          value: 10,
          name: "临时劳务",
        },
      ];
      const item = data.find((item) => {
        return item.value === id;
      });
      return item ? item.name : null;
    },
    uploadingDocumentFormater(data) {
      const index1 = data.lastIndexOf(".");
      const index2 = data.lastIndexOf("/");
      data = data.slice(index2 + 1, index1);
      return data;
    },
  },
  data() {
    return {
      projectList: [],
      positionList: [],
      departmentList: [],
      companyList: [],
      VUE_APP_SERVER: null,
    };
  },
  props: {
    descriptions: {
      type: Object,
    },
  },
  methods: {
    download(data) {
      location.href = data;
    },
  },
  created() {
    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
      }
    });
    allPosition().then((res) => {
      if (res.status === 200) {
        this.positionList = res.response;
      }
    });
    getCompanyAll().then((res) => {
      if (res.status === 200) {
        this.companyList = res.response;
      }
    });
    getDepartmentAll().then((res) => {
      if (res.status === 200) {
        this.departmentList = res.response;
      }
    });
    this.VUE_APP_SERVER = process.env.VUE_APP_SERVER;
  },
};
</script>
<style lang="scss" scoped>
.descriptions-title {
  font-size: 14px;
  font-weight: bolder;
  color: #333;
  margin: 12px 0;
}
.url {
  color: #0076ff;
  cursor: pointer;
  line-height: 20px;
}
</style>
<style scoped>
.descriptions >>> .el-table__body .cell {
  color: #606266 !important;
}
.signature {
  width: 100%;
}
</style>
