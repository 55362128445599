
<template>
  <div class="descriptions">
    <el-descriptions class="margin-top" :column="3" border>
      <el-descriptions-item
        label="Employee ID  员工编码"
        label-class-name="my-label"
      >
        {{ descriptions.EmployeeCode }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Employee Name  员工姓名"
        label-class-name="my-label"
      >
        {{ descriptions.EmployeeName }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Employee Name  拼音姓名"
        label-class-name="my-label"
      >
        {{ descriptions.EmployeeNameEN }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Nationality  国籍"
        label-class-name="my-label"
      >
        {{ descriptions.NationalityName }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Passport No.  护照号"
        label-class-name="my-label"
      >
        {{ descriptions.PassportCode }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Project Name 项目名称"
        label-class-name="my-label"
      >
        {{ descriptions.ProjectID | projectFormater(projectList) }}
      </el-descriptions-item>

      <el-descriptions-item
        label="Working Unit 用工单位"
        label-class-name="my-label"
      >
        {{ descriptions.CompanyID | companyFormater(companyList) }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Application Date 申请日期"
        label-class-name="my-label"
      >
        {{ descriptions.CreateDate | tableDateFrom }}
      </el-descriptions-item>

      <el-descriptions-item
        label="Foreign Affairs Relations 外事关系"
        label-class-name="my-label"
      >
        {{
          descriptions.ChangeForeignRelationsofApplicantto
            | labelFormater(options2)
        }}
        <i class="fa fa-edit" v-if="descriptions.FormEditable" @click="edit"></i
        ><span @click="edit" v-if="descriptions.FormEditable" class="text-sapn"
          >点击修改</span
        >
      </el-descriptions-item>
      <el-descriptions-item
        label="Visa Type 签证类型"
        label-class-name="my-label"
      >
        {{ descriptions.VisaType | labelFormater(options1) }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Visa Expiry Date 签证有效期"
        label-class-name="my-label"
      >
        {{ descriptions.VisaExpiryDate | tableDateFrom }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Air Ticket Route 飞行路线"
        label-class-name="my-label"
      >
        {{
          descriptions.AirTicketRouteofRescheduleTicket
            | ticketFormater(ticketGroupList)
        }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Departure Date from KSA 沙特起飞日期"
        label-class-name="my-label"
      >
        {{
          descriptions.DepartureDatefromKSAofRescheduleTicket | tableDateFrom
        }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Aarrival Date at KSA 沙特到达日期"
        label-class-name="my-label"
      >
        {{ descriptions.AarrivalDateatKSAofRescheduleTicket | tableDateFrom }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Reschedule Status 变更状态"
        label-class-name="my-label"
      >
        {{ descriptions.RescheduleStatus | labelFormater(options3) }}
      </el-descriptions-item>
    </el-descriptions>
    <div class="descriptions-title" v-if="descriptions.Details">明细</div>

    <el-table
      :data="descriptions.Details"
      v-if="descriptions.Details"
      :header-cell-style="{ color: '#909399', background: '#f5f7fa' }"
    >
      <el-table-column type="index" width="50" label="序号"> </el-table-column>
      <el-table-column prop="EmployeeCode" label="员工编号" width="120">
      </el-table-column>
      <el-table-column prop="EmployeeName" label="中文名" width="120">
      </el-table-column>
      <el-table-column prop="EmployeeNameEN" label="英文名" width="120">
      </el-table-column>
      <el-table-column prop="NationalityName" label="国籍" width="120">
      </el-table-column>
      <el-table-column prop="PassportCode" label="护照号" width="120">
      </el-table-column>
      <el-table-column prop="DepartureDatefromKSA" label="撤离时间" width="120">
        <template slot-scope="scope">
          {{ scope.row.DepartureDatefromKSA | tableDateFrom }}
        </template>
      </el-table-column>
    </el-table>

    <div
      class="descriptions-title"
      v-if="
        descriptions.UploadingDocument1 ||
        descriptions.UploadingDocument2 ||
        descriptions.UploadingDocument3 ||
        descriptions.UploadingDocument4 ||
        descriptions.UploadingDocument5
      "
    >
      相关附件
    </div>
    <div
      v-if="descriptions.UploadingDocument1"
      @click="download(descriptions.UploadingDocument1)"
      class="url"
    >
      {{ descriptions.UploadingDocument1 | uploadingDocumentFormater }}
    </div>
    <div
      v-if="descriptions.UploadingDocument2"
      @click="download(descriptions.UploadingDocument2)"
      class="url"
    >
      {{ descriptions.UploadingDocument2 | uploadingDocumentFormater }}
    </div>
    <div
      v-if="descriptions.UploadingDocument3"
      @click="download(descriptions.UploadingDocument3)"
      class="url"
    >
      {{ descriptions.UploadingDocument3 | uploadingDocumentFormater }}
    </div>
    <div
      v-if="descriptions.UploadingDocument4"
      @click="download(descriptions.UploadingDocument4)"
      class="url"
    >
      {{ descriptions.UploadingDocument4 | uploadingDocumentFormater }}
    </div>
    <div
      v-if="descriptions.UploadingDocument5"
      @click="download(descriptions.UploadingDocument5)"
      class="url"
    >
      {{ descriptions.UploadingDocument5 | uploadingDocumentFormater }}
    </div>

    <el-dialog
      title="修改外事关系"
      :visible.sync="dialogVisible"
      width="500px"
      :before-close="handleClose"
      top="13vh"
      class="dialog-max"
      append-to-body
    >
      <div class="dialog-main2">
        <div class="line-box">
          <div class="name">外事关系:</div>
          <el-select
            v-model="ChangeForeignRelationsofApplicantto"
            placeholder="外事关系"
            filterable
            clearable
            class="card-select"
          >
            <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="dialog-footer">
        <el-button class="pop-close" @click="handleClose">取消</el-button
        ><el-button
          class="pop-save"
          @click="putFlightTicketRescheduleApplicationItem"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getProjectAll,
  allPosition,
  getDepartmentAll,
  getCompanyAll,
} from "@/api/user";
import {
  getAirlinesAll,
  putFlightTicketRescheduleApplicationItem,
} from "@/api/table";
export default {
  filters: {
    projectFormater(id, data) {
      const item = data.find((item) => {
        return item.ProjectID === id;
      });
      return item ? item.ProjectName : null;
    },
    positionFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.PositionsCHS : null;
    },
    departmentFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.DepartmentCHS : null;
    },
    companyFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.CompanyCHS : null;
    },
    tableDateFrom(data) {
      if (data) {
        data = data.slice(0, 10);
      }
      return data;
    },
    labelFormater(id, data) {
      const item = data.find((item) => {
        return item.value === id;
      });
      return item ? item.label : null;
    },
    uploadingDocumentFormater(data) {
      const index1 = data.lastIndexOf(".");
      const index2 = data.lastIndexOf("/");
      data = data.slice(index2 + 1, index1);
      return data;
    },
    ticketFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item
        ? `${item.DeparatureCityName}——${item.ArrivalCityName}`
        : null;
    },
  },
  data() {
    return {
      projectList: [],
      positionList: [],
      departmentList: [],
      companyList: [],
      ticketGroupList: [],
      VUE_APP_SERVER: null,
      options2: [
        {
          label: "在沙",
          value: 1,
        },
        {
          label: "保留",
          value: 2,
        },
        {
          label: "调离",
          value: 3,
        },
        {
          label: "休假",
          value: 5,
        },
        {
          label: "访问",
          value: 6,
        },
      ],
      options1: [
        {
          value: 1,
          label: "Re-entry Visa",
        },
        {
          value: 2,
          label: "Exit Visa",
        },
      ],
      options3: [
        {
          value: 2,
          label: "Reschedule",
        },
        {
          value: 3,
          label: "Open",
        },
        {
          value: 4,
          label: "Cancel",
        },
      ],
      dialogVisible: false,
      ChangeForeignRelationsofApplicantto: null,
    };
  },
  props: {
    descriptions: {
      type: Object,
    },
  },
  methods: {
    download(data) {
      location.href = data;
    },
    handleClose() {
      this.dialogVisible = false;
      this.ChangeForeignRelationsofApplicantto = null;
    },
    edit() {
      this.ChangeForeignRelationsofApplicantto =
        this.descriptions.ChangeForeignRelationsofApplicantto;
      this.dialogVisible = true;
    },
    putFlightTicketRescheduleApplicationItem() {
      const data = {
        KeyID: this.descriptions.KeyID,
        ChangeForeignRelationsofApplicantto:
          this.ChangeForeignRelationsofApplicantto,
      };
      putFlightTicketRescheduleApplicationItem(data).then((res) => {
        if (res.status === 200) {
          this.descriptions.ChangeForeignRelationsofApplicantto =
            this.ChangeForeignRelationsofApplicantto;
          this.$message.success("修改成功");
          this.$emit(
            "changeForeignRelationsofApplicantto",
            data.ChangeForeignRelationsofApplicantto
          );
          this.handleClose();
        }
      });
    },
  },
  created() {
    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
      }
    });
    allPosition().then((res) => {
      if (res.status === 200) {
        this.positionList = res.response;
      }
    });
    getCompanyAll().then((res) => {
      if (res.status === 200) {
        this.companyList = res.response;
      }
    });
    getDepartmentAll().then((res) => {
      if (res.status === 200) {
        this.departmentList = res.response;
      }
    });
    //全部飞行路线
    getAirlinesAll().then((res) => {
      if (res.status === 200) {
        this.ticketGroupList = res.response;
        console.log("全部飞行路线", this.ticketGroupList);
      }
    });
    this.VUE_APP_SERVER = process.env.VUE_APP_SERVER;
  },
};
</script>
<style lang="scss" scoped>
.descriptions-title {
  font-size: 14px;
  font-weight: bolder;
  color: #333;
  margin: 12px 0;
}
.url {
  color: #0076ff;
  cursor: pointer;
  line-height: 20px;
}
.dialog-main2 {
  border-top: 1px solid #e6e6e6;
  .line-box {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #000;
    margin-top: 10px;
    padding: 0 20px;
    .name {
      flex-shrink: 0;
    }
    .el-select {
      width: 100%;
    }
  }
}
.dialog-footer {
  padding-bottom: 10px;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-right: 20px;
}
</style>
<style scoped>
.descriptions >>> .el-table__body .cell {
  color: #606266 !important;
}
.signature {
  width: 100%;
}
.fa {
  /* margin-right: 10px; */
  color: #3c8dbc;
  cursor: pointer;
  margin-left: 5px;
  cursor: pointer;
}
.text-sapn {
  font-size: 12px;
  cursor: pointer;
}
.fa:hover {
  color: #72afd2;
}
.dialog-max >>> .el-dialog__body {
  padding: 0;
}
</style>

