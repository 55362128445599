<template>
  <div class="customize-main">
    <table class="customize-form" border="0" cellpadding="0" cellspacing="0">
      <tbody>
        <tr>
          <td class="text-center" rowspan="2">
            <img class="tbale-img1" src="@/assets/image/table/tbale-img1.jpg" />
          </td>
          <td class="text-center" rowspan="2" colspan="5">
            <h1>
              Notification of Payment of Sinopec Nanjing Engineering Middle East
              Co., Ltd <br />
              中国石化南京工程中东公司付款通知单
            </h1>
          </td>
          <td class="text-right">Application Date<br />申请日期</td>
          <td class="text-center">
            {{ params.CreateDate | tableDateFrom }}
          </td>
        </tr>
        <tr>
          <td class="text-right">Workflow Table S/N<br />表单编号</td>
          <td class="text-center">
            <p>{{ params.Main.ApplicationNumber }}</p>
          </td>
        </tr>
        <tr>
          <td class="text-center">
            SubProject Name<br />
            分项目名称
          </td>
          <td class="text-center" colspan="3">{{ params.SubProjectName }}</td>
          <td class="text-center">
            Working Department<br />
            部门名称
          </td>
          <td class="text-center" colspan="3">{{ params.WorkingUnit }}</td>
        </tr>
        <tr>
          <td class="text-center">
            Payment Purpose<br />
            付款事项
          </td>
          <td class="text-center" colspan="3">{{ params.PaymentPurpose }}</td>
          <td class="text-center">
            Service Agreement S/N<br />
            合同编号
          </td>
          <td class="text-center" colspan="3">
            {{ params.ServiceAgreementSN }}
          </td>
        </tr>

        <tr>
          <td class="text-center">
            Company Name of Beneficiary<br />
            收款单位名称
          </td>
          <td class="text-center" colspan="3">
            {{ params.CompanyNameofBeneficiary }}
          </td>
          <td class="text-center">
            Abbr. Name of Supplier<br />
            岗位外包单位名称缩写
          </td>
          <td class="text-center" colspan="3">
            {{ params.CompanyNameofBeneficiary }}
          </td>
        </tr>
        <tr>
          <td class="text-center">
            Beneficiary Bank Information <br />收款账户
          </td>
          <td class="text-center" colspan="7">{{ params.BankCode }}</td>
        </tr>
        <tr>
          <td class="text-center">
            Bank Name of Beneficiary<br />
            收款银行名称
          </td>
          <td class="text-center" colspan="7">
            {{ params.BankNameofBeneficiary }}
          </td>
        </tr>
        <tr>
          <td class="text-center">
            P.O. Box & Office Address<br />
            收款人地址
          </td>
          <td class="text-center" colspan="3">
            {{ params.POBoxAndOfficeAddress }}
          </td>
          <td class="text-center">
            Contact Number<br />
            联络人联系电话
          </td>
          <td class="text-center" colspan="3">{{ params.ContactNumber }}</td>
        </tr>
        <tr>
          <td class="text-center">
            Total Value<br />
            合同金额
          </td>
          <td class="text-center">-</td>
          <td class="text-center">
            Terms of Payment<br />
            付款条款
          </td>
          <td class="text-center" colspan="5">-</td>
        </tr>
        <tr>
          <td class="text-center">
            Currency<br />
            币种
          </td>
          <td class="text-center">SAR</td>
          <td class="text-center">
            Amount in Figure<br />
            付款金额
          </td>
          <td class="text-center">{{ params.AmountWithVAT }}</td>
          <td class="text-center">Amount in Word<br />大写</td>
          <td class="text-center" colspan="3">{{ params.AmountinWord }}</td>
        </tr>
        <tr>
          <td class="text-center">
            Method Of Payment<br />
            付款方式
          </td>
          <td class="text-center" colspan="7">
            <el-radio-group v-model="params.MethodOfPayment" disabled>
              <el-radio :label="1">Cash 现金</el-radio>
              <el-radio :label="2">Check 支票 </el-radio>
              <el-radio :label="3">TT 电汇</el-radio>
              <el-radio :label="4">Draft 汇票</el-radio>
            </el-radio-group>
          </td>
        </tr>

        <tr>
          <td class="text-center" rowspan="2">Approval Record<br />审批记录</td>
          <td class="text-center2" colspan="2">
            <div class="div4">Transactor<br />经办人</div>
            <div class="div5">
              <el-input
                type="textarea"
                v-model="params.Main.TransactorRemark"
                :rows="2"
                placeholder="请输入内容"
              >
              </el-input>
            </div>
            <div class="text-box">
              <div class="sign-box">
                <span>签字Sign：</span
                ><img
                  class="sign"
                  v-if="params.Main.Transactor"
                  :src="params.Main.Transactor"
                />
              </div>
              <div>
                <span>日期Date：</span
                ><span>{{ params.Main.TransactorDate }}</span>
              </div>
            </div>
          </td>

          <td class="text-center2" colspan="2">
            <div class="div4">
              Manager of charge Department <br />业务部门负责人
            </div>
            <div class="div5">
              <el-input
                type="textarea"
                v-model="params.Main.ManagerofchargeDepartmentRemark"
                :rows="2"
                placeholder="请输入内容"
              >
              </el-input>
            </div>
            <div class="text-box">
              <div class="sign-box">
                <span>签字Sign：</span
                ><img
                  class="sign"
                  v-if="params.Main.ManagerofchargeDepartment"
                  :src="params.Main.ManagerofchargeDepartment"
                />
              </div>
              <div>
                <span>日期Date：</span
                ><span>{{ params.Main.ManagerofchargeDepartmentDate }}</span>
              </div>
            </div>
          </td>
          <td class="text-center2" colspan="3">
            <div class="div4">
              Manager of Finance Department <br />
              财务部门负责人
            </div>
            <div class="div5">
              <el-input
                type="textarea"
                v-model="params.Main.ManagerofFinanceDepartmentRemark"
                :rows="2"
                placeholder="请输入内容"
              >
              </el-input>
            </div>
            <div class="text-box">
              <div class="sign-box">
                <span>签字Sign：</span
                ><img
                  class="sign"
                  v-if="params.Main.ManagerofFinanceDepartment"
                  :src="params.Main.ManagerofFinanceDepartment"
                />
              </div>
              <div>
                <span>日期Date：</span
                ><span>{{ params.Main.ManagerofFinanceDepartmentDate }}</span>
              </div>
            </div>
          </td>
        </tr>

        <tr>
          <td class="text-center2" colspan="2">
            <div class="div4">
              Responsible Leader <br />
              分管经营领导
            </div>
            <div class="div5">
              <el-input
                type="textarea"
                v-model="params.Main.ResponsibleLeaderRemark"
                :rows="2"
                placeholder="请输入内容"
              >
              </el-input>
            </div>
            <div class="text-box">
              <div class="sign-box">
                <span>签字Sign：</span
                ><img
                  class="sign"
                  v-if="params.Main.ResponsibleLeader"
                  :src="params.Main.ResponsibleLeader"
                />
              </div>
              <div>
                <span>日期Date：</span
                ><span>{{ params.Main.ResponsibleLeaderDate }}</span>
              </div>
            </div>
          </td>
          <td class="text-center2" colspan="2">
            <div class="div4">
              Project Admin Dept. and Project Manager <br />
              项目综合部、项目经理
            </div>
            <div class="div5">
              <el-input
                type="textarea"
                v-model="params.Main.ProjectManagerRemark"
                :rows="2"
                placeholder="请输入内容"
              >
              </el-input>
            </div>
            <div class="text-box">
              <div class="sign-box">
                <span>签字Sign：</span
                ><img
                  class="sign"
                  v-if="params.Main.ProjectManager"
                  :src="params.Main.ProjectManager"
                />
              </div>
              <div>
                <span>日期Date：</span
                ><span>{{ params.Main.ProjectManagerDate }}</span>
              </div>
            </div>
          </td>
          <td class="text-center2" colspan="3">
            <div class="div4">
              Approved by SNEMC Authorized <br />
              中东公司授权人
            </div>
            <div class="div5">
              <el-input
                type="textarea"
                v-model="params.Main.ApprovedbySNEMCAuthorizedRemark"
                :rows="2"
                placeholder="请输入内容"
              >
              </el-input>
            </div>
            <div class="text-box">
              <div class="sign-box">
                <span>签字Sign：</span
                ><img
                  class="sign"
                  v-if="params.Main.ApprovedbySNEMCAuthorized"
                  :src="params.Main.ApprovedbySNEMCAuthorized"
                />
              </div>
              <div>
                <span>日期Date：</span
                ><span>{{ params.Main.ApprovedbySNEMCAuthorizedDate }}</span>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="customize-line-box">
      <div class="line-box-item">
        <div class="name">Employee:</div>
        <div class="input-box"></div>
      </div>
      <div class="line-box-item">
        <div class="name">Description:</div>
        <div class="input-box">Labor and Manpower Cost</div>
      </div>
      <div class="line-box-item">
        <div class="name">Approved:</div>
        <div class="input-box">-</div>
      </div>
      <div class="line-box-item">
        <div class="name">Amount:</div>
        <div class="input-box">{{ params.AmountWithVAT }}</div>
      </div>
      <div class="line-box-item">
        <div class="name">Pages:</div>
        <div class="input-box"></div>
      </div>
    </div>
    <div class="customize-line-box customize-line-box2">
      <div class="line-box-item">
        <div class="name">SubProject Name:</div>
        <div class="input-box">{{ params.SubProjectName }}</div>
      </div>
      <div class="line-box-item">
        <div class="name">Payment Purpose:</div>
        <div class="input-box">{{ params.PaymentPurpose }}</div>
      </div>
      <div class="line-box-item">
        <div class="name">Abbr. Name of Supplier:</div>
        <div class="input-box">{{ params.CompanyNameofBeneficiary }}</div>
      </div>
      <div class="line-box-item">
        <div class="name">Workflow Table S/N:</div>
        <div class="input-box">{{ params.Main.ApplicationNumber }}</div>
      </div>
    </div>

    <div class="Note-fa">
      <div class="Note-box">
        <div class="note-herder">Note:</div>
        <div class="p-box">
          <div class="p-box-title">1、</div>
          <div class="p-content">
            <p class="p-title">
              所有票据沿上侧、左侧虚线粘贴，票据粘贴不得超出该区域；
            </p>
            <p>
              All invoices are pasted along the dotted lines on the upper and
              left sides, and the invoices must not be pasted beyond this area.
            </p>
          </div>
        </div>
        <div class="p-box">
          <div class="p-box-title">2、</div>
          <div class="p-content">
            <p class="p-title">
              A4纸发票无需粘贴；其余发票要求粘贴平整，便于扫描。发票信息完整显示，
              不得覆盖粘贴；
            </p>
            <p>
              A4 paper invoices do not need to be pasted; other invoices need to
              be pasted flat for easy scanning. Invoice information is displayed
              in its entirety and must not be overwritten and pasted.
            </p>
          </div>
        </div>
        <div class="p-box">
          <div class="p-box-title">3、</div>
          <div class="p-content">
            <p class="p-title">
              所有发票应按费用类别分类粘贴，壹张原始粘贴单只粘贴壹类报销费用单据。
            </p>
            <p>
              Invoices should be classified and pasted according to the category
              of expenses, and only one type of reimbursement expense receipt
              should be pasted on one original pasting slip.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="table-fa">
      <div class="table-box">
        <table
          class="customize-form"
          border="0"
          cellpadding="0"
          cellspacing="0"
        >
          <tbody>
            <tr>
              <td class="text-center" rowspan="2">
                Approval Record<br />审批记录
              </td>
              <td class="text-center2" colspan="2">
                <div class="div4">Transactor<br />经办人</div>
                <div class="div5">
                  <el-input
                    type="textarea"
                    v-model="params.Main.TransactorRemark"
                    :rows="2"
                    placeholder="请输入内容"
                  >
                  </el-input>
                </div>
                <div class="text-box">
                  <div class="sign-box">
                    <span>签字Sign：</span
                    ><img
                      class="sign"
                      v-if="params.Main.Transactor"
                      :src="params.Main.Transactor"
                    />
                  </div>
                  <div>
                    <span>日期Date：</span
                    ><span>{{ params.Main.TransactorDate }}</span>
                  </div>
                </div>
              </td>

              <td class="text-center2" colspan="2">
                <div class="div4">
                  Manager of charge Department <br />业务部门负责人
                </div>
                <div class="div5">
                  <el-input
                    type="textarea"
                    v-model="params.Main.ManagerofchargeDepartmentRemark"
                    :rows="2"
                    placeholder="请输入内容"
                  >
                  </el-input>
                </div>
                <div class="text-box">
                  <div class="sign-box">
                    <span>签字Sign：</span
                    ><img
                      class="sign"
                      v-if="params.Main.ManagerofchargeDepartment"
                      :src="params.Main.ManagerofchargeDepartment"
                    />
                  </div>
                  <div>
                    <span>日期Date：</span
                    ><span>{{
                      params.Main.ManagerofchargeDepartmentDate
                    }}</span>
                  </div>
                </div>
              </td>
              <td class="text-center2" colspan="3">
                <div class="div4">
                  Manager of Finance Department <br />
                  财务部门负责人
                </div>
                <div class="div5">
                  <el-input
                    type="textarea"
                    v-model="params.Main.ManagerofFinanceDepartmentRemark"
                    :rows="2"
                    placeholder="请输入内容"
                  >
                  </el-input>
                </div>
                <div class="text-box">
                  <div class="sign-box">
                    <span>签字Sign：</span
                    ><img
                      class="sign"
                      v-if="params.Main.ManagerofFinanceDepartment"
                      :src="params.Main.ManagerofFinanceDepartment"
                    />
                  </div>
                  <div>
                    <span>日期Date：</span
                    ><span>{{
                      params.Main.ManagerofFinanceDepartmentDate
                    }}</span>
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td class="text-center2" colspan="2">
                <div class="div4">
                  Responsible Leader <br />
                  分管经营领导
                </div>
                <div class="div5">
                  <el-input
                    type="textarea"
                    v-model="params.Main.ResponsibleLeaderRemark"
                    :rows="2"
                    placeholder="请输入内容"
                  >
                  </el-input>
                </div>
                <div class="text-box">
                  <div class="sign-box">
                    <span>签字Sign：</span
                    ><img
                      class="sign"
                      v-if="params.Main.ResponsibleLeader"
                      :src="params.Main.ResponsibleLeader"
                    />
                  </div>
                  <div>
                    <span>日期Date：</span
                    ><span>{{ params.Main.ResponsibleLeaderDate }}</span>
                  </div>
                </div>
              </td>
              <td class="text-center2" colspan="2">
                <div class="div4">
                       Project Manager
                  <br />
                  项目经理
                </div>
                <div class="div5">
                  <el-input
                    type="textarea"
                    v-model="params.Main.ProjectManagerRemark"
                    :rows="2"
                    placeholder="请输入内容"
                  >
                  </el-input>
                </div>
                <div class="text-box">
                  <div class="sign-box">
                    <span>签字Sign：</span
                    ><img
                      class="sign"
                      v-if="params.Main.ProjectManager"
                      :src="params.Main.ProjectManager"
                    />
                  </div>
                  <div>
                    <span>日期Date：</span
                    ><span>{{ params.Main.ProjectManagerDate }}</span>
                  </div>
                </div>
              </td>
              <td class="text-center2" colspan="3">
                <div class="div4">
                  Approved by SNEMC Authorized <br />
                  中东公司授权人
                </div>
                <div class="div5">
                  <el-input
                    type="textarea"
                    v-model="params.Main.ApprovedbySNEMCAuthorizedRemark"
                    :rows="2"
                    placeholder="请输入内容"
                  >
                  </el-input>
                </div>
                <div class="text-box">
                  <div class="sign-box">
                    <span>签字Sign：</span
                    ><img
                      class="sign"
                      v-if="params.Main.ApprovedbySNEMCAuthorized"
                      :src="params.Main.ApprovedbySNEMCAuthorized"
                    />
                  </div>
                  <div>
                    <span>日期Date：</span
                    ><span>{{
                      params.Main.ApprovedbySNEMCAuthorizedDate
                    }}</span>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { getProjectAll, getDepartmentAll, allPosition } from "@/api/user";

export default {
  filters: {
    tableDateFrom(data) {
      if (data) {
        data = data.slice(0, 10);
      }
      return data;
    },
    projectFormater(id, data) {
      const item = data.find((item) => {
        return item.ProjectID === id;
      });
      return item ? item.ProjectName : "-";
    },
    positionFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.PositionsCHS : "-";
    },
    departmentFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.DepartmentCHS : "-";
    },
  },
  data() {
    return {
      params: {
        AbbreviationName: null,
        AmountWithVAT: null,
        AmountWithoutVAT: null,
        AmountinWord: null,
        ApplicationCreated: null,
        ApplicationNumber: null,
        Attachments: [],
        BankCode: null,
        BankNameofBeneficiary: null,
        CompanyID: null,
        CompanyNameofBeneficiary: null,
        ContactNumber: null,
        CostCenter: null,
        CostCenterCode: null,
        CostCenterName: null,
        CreateDate: null,
        CreateID: null,
        Creator: null,
        Deleted: null,
        DepartmentID: null,
        Details: null,
        FirstParty: null,
        FirstPartyName: null,
        ID: null,
        KeyID: null,
        MDMSystemSN: null,
        MainKeyID: null,
        Modifier: null,
        ModifyDate: null,
        ModifyID: null,
        MonthlyCost: null,
        POBoxAndOfficeAddress: null,
        PaymentPurpose: null,
        ProjectID: null,
        ProjectName: null,
        Remark: null,
        ServiceAgreementSN: null,
        SubProjectID: null,
        SubProjectName: null,
        Supplier: null,
        SupplierKeyID: null,
        SupplierName: null,
        VATAmount: null,
        WorkingDept: null,
        WorkingUnit: null,
        Main: {},
      },
      radio: 1,
    };
  },
  methods: {
    clearData() {
      this.params = {
        AbbreviationName: null,
        AmountWithVAT: null,
        AmountWithoutVAT: null,
        AmountinWord: null,
        ApplicationCreated: null,
        ApplicationNumber: null,
        Attachments: [],
        BankCode: null,
        BankNameofBeneficiary: null,
        CompanyID: null,
        CompanyNameofBeneficiary: null,
        ContactNumber: null,
        CostCenter: null,
        CostCenterCode: null,
        CostCenterName: null,
        CreateDate: null,
        CreateID: null,
        Creator: null,
        Deleted: null,
        DepartmentID: null,
        Details: null,
        FirstParty: null,
        FirstPartyName: null,
        ID: null,
        KeyID: null,
        MDMSystemSN: null,
        MainKeyID: null,
        Modifier: null,
        ModifyDate: null,
        ModifyID: null,
        MonthlyCost: null,
        POBoxAndOfficeAddress: null,
        PaymentPurpose: null,
        ProjectID: null,
        ProjectName: null,
        Remark: null,
        ServiceAgreementSN: null,
        SubProjectID: null,
        SubProjectName: null,
        Supplier: null,
        SupplierKeyID: null,
        SupplierName: null,
        VATAmount: null,
        WorkingDept: null,
        WorkingUnit: null,
      };
    },

    //编辑回显
    getEditData(data) {
      this.params = data;
    },
  },
  created() {
    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
      }
    });
    getDepartmentAll().then((res) => {
      if (res.status === 200) {
        this.departmentList = res.response;
      }
    });
    allPosition().then((res) => {
      if (res.status === 200) {
        this.positionList = res.response;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.customize-main {
  width: 100%;
  .customize-form {
    font-size: 11px;
    width: 100%;
    border-left: 1px solid black;
    border-top: 1px solid black;
    tr td {
      border-right: 1px solid black;
      border-bottom: 1px solid black;
      min-width: 100px;
    }
    .text-center {
      text-align: center;
      font-size: 13px;
      padding: 10px 5px;
      color: #000;
      .tbale-img1 {
        width: 100%;
      }
      h1 {
        font-size: 20px;
        color: #000;
        margin: 10px 0;
      }
      .el-checkbox {
        margin: 10px 0;
      }
      span {
        margin-right: 20px;
        margin-left: 5px;
      }
      .input-box {
        display: inline-block;
        width: 100px;
        border-bottom: 1px solid #000;
        margin: 10px 0;
      }
    }
    .text-center2 {
      font-size: 13px;
      color: #000;
      .el-checkbox {
        margin: 10px 0;
        margin-left: 10px;
      }
      span {
        margin-right: 20px;
        margin-left: 5px;
      }
      .div1 {
        line-height: 40px;
        border-bottom: 1px solid #e0e0e0;
      }
      .div4 {
        line-height: 22px;
        border-bottom: 1px solid #e0e0e0;
        text-align: center;
        padding: 5px;
      }
      .div3 {
        line-height: 22px;
        border-top: 1px solid #e0e0e0;
        padding: 5px;
      }
      .div2 {
        padding: 5px;
        height: 100px;
        display: flex;
        align-items: center;
      }
      .div5 {
        padding: 5px;
      }
      .text-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px;
        min-height: 30px;
        div {
          width: 50%;
        }
        .sign-box {
          display: flex;
          align-items: center;
        }
      }
    }
    .text-left {
      padding: 5px;
      font-size: 13px;
      color: #000;
      .flex {
        display: flex;
        flex-wrap: wrap;
        font-size: 13px;
        color: #000;
        align-items: center;
        .el-checkbox {
          font-size: 13px;
          color: #000;

          margin: 5px 10px;
        }
        .M {
          margin-right: 30px;
        }
        .name {
          margin-right: 30px;
        }
        .input-box {
          width: 100px;
          border-bottom: 1px solid #000;
          margin: 0 10px;
        }
      }
      .flex2 {
        font-size: 13px;
        color: #000;
        align-items: center;
        display: flex;
        .el-checkbox {
          font-size: 13px;
          color: #000;

          margin: 5px 10px;
        }
        .M {
          margin-right: 30px;
        }
        .name {
          margin-right: 30px;
        }
        .input-box {
          width: 100px;
          border-bottom: 1px solid #000;
          margin: 0 10px;
        }
      }
      .text-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        div {
          width: 50%;
        }
        .sign-box {
          display: flex;
          align-items: center;
        }
      }
    }
    .text-left2 {
      text-align: left;
      font-size: 13px;
      color: #000;
      padding: 5px;
      .el-checkbox {
        margin: 10px 0;
      }
      span {
        margin-right: 20px;
        margin-left: 5px;
      }
      .zhan {
        min-height: 20px;
      }
    }
    .text-size {
      font-size: 16px;
      color: #000;
      .el-checkbox {
        margin: 10px 0;
      }
      span {
        margin-right: 20px;
      }
      div {
        margin: 10px 0;
      }
      .input-box {
        display: inline-block;
        width: 100px;
        border-bottom: 1px solid #000;
        margin: 10px 0;
      }
    }
    .text-right {
      font-size: 13px;
      text-align: right;
      padding: 5px;
    }
  }
  .customize-form2 {
    border-top: none;
  }
  .el-select {
    width: 100%;
  }
  .el-date-editor {
    width: 100%;
  }
  .customize-line-box {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .line-box-item {
      width: 19%;
      height: 50px;
      display: flex;
      align-items: center;
      .name {
        font-size: 16px;
        flex-shrink: 0;
        max-width: 150px;
        text-align: center;
        margin-right: 2px;
      }
      .input-box {
        width: 100%;
        height: 50px;
        border-bottom: 1px solid #000;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .customize-line-box2 {
    .line-box-item {
      width: 24%;
    }
  }
}
.Note-fa {
  width: 100%;
  margin-top: 100px;
  .Note-box {
    // margin-left: 100px;
    // margin-right: 100px;
    // max-width: calc(100% - 200px);
    .note-herder {
      font-size: 18px;
      font-weight: bolder;
      color: #000;
    }

    .p-box {
      display: flex;
      margin-top: 24px;
      .p-box-title {
        flex-shrink: 0;
      }
      .p-content {
        p {
          font-size: 14px;
          color: #000;
        }
        .p-title {
          text-indent: 0;
        }
      }
    }
  }
}
.table-fa {
  width: 100%;
  margin-top: 100px;
  // .table-box {
    // margin-left: 100px;
    // margin-right: 100px;
    // max-width: calc(100% - 200px);
  // }
}
</style>
<style scoped>
/* .customize-main >>> .el-input__inner { */
/* border: none; */
/* } */
.input-box >>> .el-input__inner {
  border: none;
  padding: 0;
}
.text-size >>> .el-checkbox__input {
  margin-right: 10px;
}
.el-checkbox >>> .el-checkbox__inner {
  background-color: #fff !important;
  border-color: #000 !important;
}
.el-checkbox >>> .el-checkbox__inner::after {
  box-sizing: content-box;
  content: "";
  border-color: #000;
}
.sign {
  width: 100%;
  max-width: 100px;
}
.text-center
  >>> .el-radio__input.is-disabled.is-checked
  .el-radio__inner::after {
  background-color: #7e7c7c;
}
</style>