<template>
  <div class="descriptions">
    <el-descriptions class="margin-top" :column="3" border>
      <el-descriptions-item
        label="Project Name 项目名称"
        label-class-name="my-label"
      >
        {{ descriptions.ProjectName}}
      </el-descriptions-item>
      <el-descriptions-item
        label="SubProject Name 分项目名称"
        label-class-name="my-label"
      >
        {{ descriptions.SubProjectName }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Working Unit 用工单位"
        label-class-name="my-label"
      >
        {{ descriptions.WorkingUnit}}
      </el-descriptions-item>
      <el-descriptions-item
        label="Purpose 动迁目的"
        label-class-name="my-label"
      >
      {{descriptions.Purpose}}
      </el-descriptions-item>
      <el-descriptions-item label="Date 动迁日期" label-class-name="my-label">
        {{descriptions.RelocationDate | tableDateFrom}}
      </el-descriptions-item>
      <el-descriptions-item
        label="Visa Type 签证类型"
        label-class-name="my-label"
      >
        {{ descriptions.VisaType | opinionsFrom(opinions1) }}
      </el-descriptions-item>
      <el-descriptions-item
        label="New Sponsor 新办签证"
        label-class-name="my-label"
      >
        {{ descriptions.Sponsor | SponsorFormater }}
      </el-descriptions-item>
    </el-descriptions>
    <div class="descriptions-title" v-if="descriptions.Details">明细</div>

    <el-table
      :data="descriptions.Details"
      v-if="descriptions.Details"
      :header-cell-style="{ color: '#909399', background: '#f5f7fa' }"
    >
      <el-table-column type="index" width="50" label="序号"> </el-table-column>
      <el-table-column prop="EmployeeCode" label="员工编号" width="80">
      </el-table-column>
      <el-table-column prop="EmployeeNameEN" label="英文名" width="100">
      </el-table-column>
       <el-table-column prop="IDofHomeCountry" label="员工母国身份证号" width="140">
      </el-table-column>
      </el-table-column>
      <el-table-column prop="JobProfessionName" label="岗位名称" width="80">
      </el-table-column>

      <el-table-column prop="PassportCode" label="护照号" width="80">
      </el-table-column>
     <el-table-column prop="PlaceofIssue" label="签发地" width="80">
      </el-table-column>
        <el-table-column prop="PassportType" label="护照类型" width="60">
             <template slot-scope="scope">
                {{scope.row.PassportType===0?'因私':'因公'}}
             </template>
      </el-table-column>
      <el-table-column prop="PassportValidDate" label="护照有效期 " width="80">
        <template slot-scope="scope">
          {{ scope.row.PassportValidDate | tableDateFrom }}
        </template>
      </el-table-column>
  <el-table-column prop="CountryStatyDurationTimes" label="国家/停留时长/次数" width="80">
      </el-table-column>

     <el-table-column prop="PreviousSponsorVisaTypeDemobilizationDate" label="原Sponsor/签证类型/撤离时间" width="80">
      </el-table-column>
      <el-table-column prop="EmploymentType" label="用工形式 " width="80">
        <template slot-scope="scope">
          {{ scope.row.EmploymentType | employmentTypeFormater }}
        </template>
      </el-table-column>
    </el-table>

    <div
      class="descriptions-title"
      v-if="
        descriptions.PassportCopyUrl ||
        descriptions.HomeCountryIDCardCopyUrl ||
        descriptions.HomeCountryMedicalReportUrl ||
        descriptions.OverageApprovalLetterUrl 
      "
    >
      相关附件
    </div>
    <div
      v-if="descriptions.PassportCopyUrl"
      @click="download(descriptions.PassportCopyUrl)"
      class="url"
    >
      {{ descriptions.PassportCopyUrl | uploadingDocumentFormater }}
    </div>
    <div
      v-if="descriptions.HomeCountryIDCardCopyUrl"
      @click="download(descriptions.HomeCountryIDCardCopyUrl)"
      class="url"
    >
      {{ descriptions.HomeCountryIDCardCopyUrl | uploadingDocumentFormater }}
    </div>
    <div
      v-if="descriptions.HomeCountryMedicalReportUrl"
      @click="download(descriptions.HomeCountryMedicalReportUrl)"
      class="url"
    >
      {{ descriptions.HomeCountryMedicalReportUrl | uploadingDocumentFormater }}
    </div>
    <div
      v-if="descriptions.OverageApprovalLetterUrl"
      @click="download(descriptions.OverageApprovalLetterUrl)"
      class="url"
    >
      {{ descriptions.OverageApprovalLetterUrl | uploadingDocumentFormater }}
    </div>
 
  </div>
</template>

<script>
import {
  getProjectAll,
  allPosition,
  getDepartmentAll,
  getCompanyAll,
} from "@/api/user";
export default {
  filters: {
    projectFormater(id, data) {
      const item = data.find((item) => {
        return item.ProjectID === id;
      });
      return item ? item.ProjectName : null;
    },
    positionFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.PositionsCHS : null;
    },
    departmentFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.DepartmentCHS : null;
    },
    companyFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.CompanyCHS : null;
    },
    tableDateFrom(data) {
      if (data) {
        data = data.slice(0, 10);
      }
      return data;
    },
    opinionsFrom(id, data) {
      const item = data.find((item) => {
        return item.value === id;
      });
      return item ? item.label : null;
    },
    SponsorFormater(id) {
      const data = [
        {
          label: "SNEI",
          value: 1,
        },
        {
          label: "SEG",
          value: 2,
        },
        {
          label: "其它",
          value: 3,
        },
      ];
      const item = data.find((item) => {
        return item.value === id;
      });
      return item ? item.label : null;
    },
    employmentTypeFormater(id) {
      const data = [
        {
          value: 1,
          name: "正式职工",
        },
        {
          value: 2,
          name: "直聘",
        },
        {
          value: 3,
          name: "岗位外包",
        },
        {
          value: 4,
          name: "国内分包",
        },
        {
          value: 5,
          name: "岗位外包",
        },
        {
          value: 6,
          name: "外籍员工",
        },

        {
          value: 7,
          name: "虚拟沙化",
        },
        {
          value: 8,
          name: "常设劳务",
        },
        {
          value: 9,
          name: "当地分包",
        },
        {
          value: 10,
          name: "临时劳务",
        },
      ];
      const item = data.find((item) => {
        return item.value === id;
      });
      return item ? item.name : null;
    },
    uploadingDocumentFormater(data) {
      const index1 = data.lastIndexOf(".");
      const index2 = data.lastIndexOf("/");
      data = data.slice(index2 + 1, index1);
      return data;
    },
  },
  data() {
    return {
      projectList: [],
      positionList: [],
      departmentList: [],
      companyList: [],
      VUE_APP_SERVER: null,
      opinions1: [
        {
          value: 1,
          label: "工作",
        },
        {
          value: 2,
          label: "工访",
        },
        {
          value: 3,
          label: "商访",
        },
        {
          value: 4,
          label: "家访",
        },
      ],
    };
  },
  props: {
    descriptions: {
      type: Object,
    },
  },
  methods: {
    download(data) {
      location.href = data;
    },
  },
  created() {
    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
      }
    });
    allPosition().then((res) => {
      if (res.status === 200) {
        this.positionList = res.response;
      }
    });
    getCompanyAll().then((res) => {
      if (res.status === 200) {
        this.companyList = res.response;
      }
    });
    getDepartmentAll().then((res) => {
      if (res.status === 200) {
        this.departmentList = res.response;
      }
    });
    this.VUE_APP_SERVER = process.env.VUE_APP_SERVER;
  },
};
</script>
<style lang="scss" scoped>
.descriptions-title {
  font-size: 14px;
  font-weight: bolder;
  color: #333;
  margin: 12px 0;
}
.url {
  color: #0076ff;
  cursor: pointer;
  line-height: 20px;
}
</style>
<style scoped>
.descriptions >>> .el-table__body .cell {
  color: #606266 !important;
}
.signature {
  width: 100%;
}
</style>
