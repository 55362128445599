var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"descriptions"},[_c('el-descriptions',{staticClass:"margin-top",attrs:{"column":3,"border":""}},[_c('el-descriptions-item',{attrs:{"label":"Application Date 申请日期","label-class-name":"my-label"}},[_vm._v(" "+_vm._s(_vm._f("tableDateFrom")(_vm.descriptions.CreateDate))+" ")]),_c('el-descriptions-item',{attrs:{"label":"Workflow Table S/N 表单编号","label-class-name":"my-label"}},[_vm._v(" "+_vm._s(_vm.descriptions.ApplicationNumber)+" ")]),_c('el-descriptions-item',{attrs:{"label":"Creator 申请人","label-class-name":"my-label"}},[_vm._v(" "+_vm._s(_vm.descriptions.Creator)+" ")])],1),(_vm.descriptions.Details)?_c('div',{staticClass:"descriptions-title"},[_vm._v("明细")]):_vm._e(),(_vm.descriptions.Details)?_c('el-table',{attrs:{"data":_vm.descriptions.Details,"header-cell-style":{ color: '#909399', background: '#f5f7fa' }}},[_c('el-table-column',{attrs:{"type":"index","label":"序号","width":"50"}}),_c('el-table-column',{attrs:{"prop":"EmployeeCode","label":"员工编码","width":"80"}}),_c('el-table-column',{attrs:{"prop":"NameinEnglish","label":"拼音姓名","width":"80"}}),_c('el-table-column',{attrs:{"prop":"ProjectName","label":"项目部","width":"90"}}),_c('el-table-column',{attrs:{"prop":"WorkingUnit","label":"用工单位","width":"90"}}),_c('el-table-column',{attrs:{"prop":"IQAMAID","label":"暂住证号","width":"90"}}),_c('el-table-column',{attrs:{"prop":"BorderNumber","label":"海关入境号","width":"90"}}),_c('el-table-column',{attrs:{"prop":"BorderNumber","label":"SPONSOR","width":"90"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm._f("SponsorFormater")(scope.row.SPONSOR))+" ")]}}],null,false,2730525424)})],1):_vm._e(),(
      _vm.descriptions.UploadingDocument1 ||
      _vm.descriptions.UploadingDocument2 ||
      _vm.descriptions.UploadingDocument3 ||
      _vm.descriptions.UploadingDocument4 ||
      _vm.descriptions.UploadingDocument5
    )?_c('div',{staticClass:"descriptions-title"},[_vm._v(" 相关附件 ")]):_vm._e(),(_vm.descriptions.UploadingDocument1)?_c('div',{staticClass:"url",on:{"click":function($event){return _vm.download(_vm.descriptions.UploadingDocument1)}}},[_vm._v(" "+_vm._s(_vm._f("uploadingDocumentFormater")(_vm.descriptions.UploadingDocument1))+" ")]):_vm._e(),(_vm.descriptions.UploadingDocument2)?_c('div',{staticClass:"url",on:{"click":function($event){return _vm.download(_vm.descriptions.UploadingDocument2)}}},[_vm._v(" "+_vm._s(_vm._f("uploadingDocumentFormater")(_vm.descriptions.UploadingDocument2))+" ")]):_vm._e(),(_vm.descriptions.UploadingDocument3)?_c('div',{staticClass:"url",on:{"click":function($event){return _vm.download(_vm.descriptions.UploadingDocument3)}}},[_vm._v(" "+_vm._s(_vm._f("uploadingDocumentFormater")(_vm.descriptions.UploadingDocument3))+" ")]):_vm._e(),(_vm.descriptions.UploadingDocument4)?_c('div',{staticClass:"url",on:{"click":function($event){return _vm.download(_vm.descriptions.UploadingDocument4)}}},[_vm._v(" "+_vm._s(_vm._f("uploadingDocumentFormater")(_vm.descriptions.UploadingDocument4))+" ")]):_vm._e(),(_vm.descriptions.UploadingDocument5)?_c('div',{staticClass:"url",on:{"click":function($event){return _vm.download(_vm.descriptions.UploadingDocument5)}}},[_vm._v(" "+_vm._s(_vm._f("uploadingDocumentFormater")(_vm.descriptions.UploadingDocument5))+" ")]):_vm._e(),_c('el-dialog',{staticClass:"dialog-max",attrs:{"title":"当地岗位服务外包费用结算单","visible":_vm.dialogVisible,"width":"99vw","before-close":_vm.handleClose,"append-to-body":"","top":"1vh"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',{staticClass:"dialog-main"},[_c('ManpowerSupplierMonthlyCostApplication',{ref:"manpowerSupplierMonthlyCostApplication",on:{"close":_vm.handleClose}})],1),_c('div',{staticClass:"dialog-footer"},[_c('el-button',{staticClass:"pop-close",on:{"click":_vm.handleClose}},[_vm._v("关闭")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }