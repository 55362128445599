<template>
  <div class="descriptions">
    <el-descriptions class="margin-top" :column="3" border>
      <el-descriptions-item
        label="Application Date 申请日期"
        label-class-name="my-label"
      >
        {{ descriptions.CreateDate | tableDateFrom }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Workflow Table S/N 表单编号"
        label-class-name="my-label"
      >
        {{ descriptions.ApplicationNumber }}
      </el-descriptions-item>
      <el-descriptions-item label="Creator 申请人" label-class-name="my-label">
        {{ descriptions.Creator }}
      </el-descriptions-item>
      <el-descriptions-item label="Invitation Letter Type Name	 邀请函类型" label-class-name="my-label">
        {{ descriptions.InvitationLetterTypeName	 }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Company Name in English  公司英文名称"
        label-class-name="my-label"
      >
        {{ descriptions.CompanyNameinEnglish }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Company CR NO  营业执照号"
        label-class-name="my-label"
      >
        {{ descriptions.CompanyCRCode }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Invitation type 邀请项目名称"
        label-class-name="my-label"
      >
        {{ descriptions.ProjectID	 | projectFormater(projectList) }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Invite SubProject Name 邀请分项目名称"
        label-class-name="my-label"
      >
        {{ descriptions.SubProjectName }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Invite Working Unit 邀请用工单位"
        label-class-name="my-label"
      >
        {{ descriptions.CompanyID | companyFormater(companyList) }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Business scope 营业范围（英文）"
        label-class-name="my-label"
      >
        {{ descriptions.BusinessScope }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Invitation Puporse  来访原因 "
        label-class-name="my-label"
      >
        {{ descriptions.InvitationPuporse }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Place of Visa Application  签证办理地点"
        label-class-name="my-label"
      >
        {{ descriptions.PlaceofVisaApplication }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Valid Duration  签证有效期"
        label-class-name="my-label"
      >
        {{ descriptions.ValidDuration === 1 ? "30天" : "90天" }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Single/Multiple Entry  入境次数"
        label-class-name="my-label"
      >
        {{ descriptions.SingleMultipleEntry === 1 ? "单次" : "多次" }}
      </el-descriptions-item>
    </el-descriptions>
    <div class="descriptions-title" v-if="descriptions.Details">明细</div>

    <el-table
      :data="descriptions.Details"
      v-if="descriptions.Details"
      :header-cell-style="{ color: '#909399', background: '#f5f7fa' }"
    >
      <el-table-column type="index" width="50" label="序号"> </el-table-column>
      <el-table-column prop="EmployeeCode" label="员工编号" width="80">
      </el-table-column>
      <el-table-column prop="EmployeeNameEN" label="英文名" width="80">
      </el-table-column>
      <el-table-column prop="Gender" label="性别" width="40">
        <template slot-scope="scope">
          {{ scope.row.Gender === 1 ? "男" : "女" }}
        </template>
      </el-table-column>
      <el-table-column prop="NationalityName" label="国籍" width="80">
      </el-table-column>
      <el-table-column prop="DateofBirth" label="生日" width="80">
        <template slot-scope="scope">
          {{ scope.row.DateofBirth | tableDateFrom }}
        </template>
      </el-table-column>
      <el-table-column prop="Age" label="年龄" width="80"> </el-table-column>
      <el-table-column prop="ReligionName" label="宗教信仰" width="80">
      </el-table-column>
      <el-table-column prop="JobProfessionName" label="岗位名称" width="80">
      </el-table-column>

      <el-table-column prop="PassportCode" label="护照号" width="120">
      </el-table-column>
      <el-table-column prop="PassportValidDate" label="护照有效期 " width="80">
        <template slot-scope="scope">
          {{ scope.row.PassportValidDate | tableDateFrom }}
        </template>
      </el-table-column>
      <el-table-column prop="EmploymentType" label="用工形式 " width="80">
        <template slot-scope="scope">
          {{ scope.row.EmploymentType | employmentTypeFormater }}
        </template>
      </el-table-column>
    </el-table>

    <div
      class="descriptions-title"
      v-if="
        descriptions.UploadingDocument1 ||
        descriptions.UploadingDocument2 ||
        descriptions.UploadingDocument3 ||
        descriptions.UploadingDocument4 ||
        descriptions.UploadingDocument5
      "
    >
      相关附件
    </div>
    <div
      v-if="descriptions.UploadingDocument1"
      @click="download(descriptions.UploadingDocument1)"
      class="url"
    >
      {{ descriptions.UploadingDocument1 | uploadingDocumentFormater }}
    </div>
    <div
      v-if="descriptions.UploadingDocument2"
      @click="download(descriptions.UploadingDocument2)"
      class="url"
    >
      {{ descriptions.UploadingDocument2 | uploadingDocumentFormater }}
    </div>
    <div
      v-if="descriptions.UploadingDocument3"
      @click="download(descriptions.UploadingDocument3)"
      class="url"
    >
      {{ descriptions.UploadingDocument3 | uploadingDocumentFormater }}
    </div>
    <div
      v-if="descriptions.UploadingDocument4"
      @click="download(descriptions.UploadingDocument4)"
      class="url"
    >
      {{ descriptions.UploadingDocument4 | uploadingDocumentFormater }}
    </div>
    <div
      v-if="descriptions.UploadingDocument5"
      @click="download(descriptions.UploadingDocument5)"
      class="url"
    >
      {{ descriptions.UploadingDocument5 | uploadingDocumentFormater }}
    </div>
  </div>
</template>

<script>
import {
  getProjectAll,
  allPosition,
  getDepartmentAll,
  getCompanyAll,
} from "@/api/user";
export default {
  filters: {
    projectFormater(id, data) {
      const item = data.find((item) => {
        return item.ProjectID === id;
      });
      return item ? item.ProjectName : null;
    },
    positionFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.PositionsCHS : null;
    },
    departmentFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.DepartmentCHS : null;
    },
    companyFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.CompanyCHS : null;
    },
    tableDateFrom(data) {
      if (data) {
        data = data.slice(0, 10);
      }
      return data;
    },
    employmentTypeFormater(id) {
      const data = [
        {
          value: 1,
          name: "正式职工",
        },
        {
          value: 2,
          name: "直聘",
        },
        {
          value: 3,
          name: "岗位外包",
        },
        {
          value: 4,
          name: "国内分包",
        },
        {
          value: 5,
          name: "岗位外包",
        },
        {
          value: 6,
          name: "外籍员工",
        },

        {
          value: 7,
          name: "虚拟沙化",
        },
        {
          value: 8,
          name: "常设劳务",
        },
        {
          value: 9,
          name: "当地分包",
        },
        {
          value: 10,
          name: "临时劳务",
        },
      ];
      const item = data.find((item) => {
        return item.value === id;
      });
      return item ? item.name : null;
    },
    uploadingDocumentFormater(data) {
      const index1 = data.lastIndexOf(".");
      const index2 = data.lastIndexOf("/");
      data = data.slice(index2 + 1, index1);
      return data;
    },
  },
  data() {
    return {
      projectList: [],
      positionList: [],
      departmentList: [],
      companyList: [],
      VUE_APP_SERVER: null,
    };
  },
  props: {
    descriptions: {
      type: Object,
    },
  },
  methods: {
    download(data) {
      location.href = data;
    },
  },
  created() {
    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
      }
    });
    allPosition().then((res) => {
      if (res.status === 200) {
        this.positionList = res.response;
      }
    });
    getCompanyAll().then((res) => {
      if (res.status === 200) {
        this.companyList = res.response;
      }
    });
    getDepartmentAll().then((res) => {
      if (res.status === 200) {
        this.departmentList = res.response;
      }
    });
    this.VUE_APP_SERVER = process.env.VUE_APP_SERVER;
  },
};
</script>
<style lang="scss" scoped>
.descriptions-title {
  font-size: 14px;
  font-weight: bolder;
  color: #333;
  margin: 12px 0;
}
.url {
  color: #0076ff;
  cursor: pointer;
  line-height: 20px;
}
</style>
<style scoped>
.descriptions >>> .el-table__body .cell {
  color: #606266 !important;
}
.signature {
  width: 100%;
}
</style>
