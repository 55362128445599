<template>
  <div class="descriptions">
    <el-descriptions class="margin-top" :column="3" border>
      <el-descriptions-item label="PO No." label-class-name="my-label">
        {{ descriptions.PONO }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Application Date 申请日期"
        label-class-name="my-label"
      >
        {{ descriptions.CreateDate | tableDateFrom }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Workflow Table S/N 表单编号"
        label-class-name="my-label"
      >
        {{ descriptions.ApplicationNumber }}
      </el-descriptions-item>
      <el-descriptions-item label="To Second Party" label-class-name="my-label">
        {{ descriptions.SupplierName }}
      </el-descriptions-item>
      <el-descriptions-item
        label="From First Party"
        label-class-name="my-label"
      >
        {{ descriptions.FirstPartyName }}
      </el-descriptions-item>
      <el-descriptions-item label="Project Name" label-class-name="my-label">
        {{ descriptions.ProjectName }}
      </el-descriptions-item>
    </el-descriptions>
    <div class="descriptions-title" v-if="descriptions.Details">明细</div>

    <el-table
      :data="descriptions.Details"
      v-if="descriptions.Details"
      :row-class-name="tableRowClassName"
      :header-cell-style="{ color: '#909399', background: '#f5f7fa' }"
    >
      <el-table-column type="index" width="50" label="SN"> </el-table-column>
      <el-table-column
        prop="CategoryName"
        label="Category & Discipline"
        width="130"
      >
      </el-table-column>
      <el-table-column
        prop="JobProfessionName"
        label="Job Profession"
        width="120"
      >
      </el-table-column>

      <el-table-column prop="QulificationName" label="Qualif" width="80">
      </el-table-column>
      <el-table-column prop="Qty" label="Qty" width="50"> </el-table-column
      ><el-table-column prop="Rate" label="Rate (SR/Hr)" width="100">
      </el-table-column
      ><el-table-column
        prop="FATBearedByName"
        label="FAT Beared By"
        width="100"
      >
      </el-table-column
      ><el-table-column
        prop="ReferencePONO"
        label="Reference PO. No."
        width="100"
      >
      </el-table-column
      ><el-table-column prop="POStatus" label="PO Status" width="80">
        <template slot-scope="scope"
          >{{ scope.row.POStatus === 1 ? "Valid" : "Terminated" }}
        </template> </el-table-column
      ><el-table-column prop="Remarks" label="Remarks" width="80">
      </el-table-column>
    </el-table>

    <div
      class="descriptions-title"
      v-if="descriptions.Attachments && descriptions.Attachments.length !== 0"
    >
      相关附件
    </div>
    <div
      v-for="item in descriptions.Attachments"
      @click="download(item.Url)"
      class="url"
      :key="item.ID"
    >
      {{ item.Name }}
    </div>
  </div>
</template>

<script>
import {
  getProjectAll,
  allPosition,
  getDepartmentAll,
  getCompanyAll,
} from "@/api/user";
export default {
  filters: {
    projectFormater(id, data) {
      const item = data.find((item) => {
        return item.ProjectID === id;
      });
      return item ? item.ProjectName : null;
    },
    positionFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.PositionsCHS : null;
    },
    departmentFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.DepartmentCHS : null;
    },
    companyFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.CompanyCHS : null;
    },
    tableDateFrom(data) {
      if (data) {
        data = data.slice(0, 10);
      }
      return data;
    },
  },
  data() {
    return {
      projectList: [],
      positionList: [],
      departmentList: [],
      companyList: [],
      VUE_APP_SERVER: null,
    };
  },
  props: {
    descriptions: {
      type: Object,
    },
  },
  methods: {
    download(data) {
      location.href = this.VUE_APP_SERVER + "upload/" + data;
    },
    tableRowClassName({ row, rowIndex }) {
      console.log("row", row.OutofPrice);
      if (row.OutofGuidancePrice === 1) {
        return "warning-row";
      }
    },
  },
  created() {
    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
      }
    });
    allPosition().then((res) => {
      if (res.status === 200) {
        this.positionList = res.response;
      }
    });
    getCompanyAll().then((res) => {
      if (res.status === 200) {
        this.companyList = res.response;
      }
    });
    getDepartmentAll().then((res) => {
      if (res.status === 200) {
        this.departmentList = res.response;
      }
    });
    this.VUE_APP_SERVER = process.env.VUE_APP_SERVER;
  },
};
</script>
<style lang="scss" scoped>
.descriptions-title {
  font-size: 14px;
  font-weight: bolder;
  color: #333;
  margin: 12px 0;
}
.url {
  color: #0076ff;
  cursor: pointer;
  line-height: 20px;
}
</style>
<style scoped>
.descriptions >>> .el-table__body .cell {
  color: #606266 !important;
}
.signature {
  width: 100%;
}
.el-table >>> .warning-row {
  background: #ecf5ff !important;
}
</style>
