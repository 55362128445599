<template>
  <div class="descriptions">
    <el-descriptions class="margin-top" :column="3" border>
     <el-descriptions-item
        label="Application Date 申请日期"
        label-class-name="my-label"
      >
        {{ descriptions.CreateDate  }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Workflow Table S/N 表单编号"
        label-class-name="my-label"
      >
        {{ descriptions.ApplicationNumber }}
      </el-descriptions-item>
      <el-descriptions-item label="Creator 申请人" label-class-name="my-label">
        {{ descriptions.Creator }}
      </el-descriptions-item>
    </el-descriptions>
    </el-descriptions>
    <div class="descriptions-title" v-if="descriptions.Details">明细</div>

    <el-table
      :data="descriptions.Details"
      v-if="descriptions.Details"
      :header-cell-style="{ color: '#909399', background: '#f5f7fa' }"
    >
      <el-table-column type="index" width="50" label="序号"> </el-table-column>
      <el-table-column prop="MonthlyCost" label="挂账月份" width="80">
        <template slot-scope="scope">
          {{ scope.row.MonthlyCost | tableDateFrom2 }}
        </template>
      </el-table-column>
      <el-table-column prop="FirstPartyName" label="甲方" width="120">
      </el-table-column>
      <el-table-column prop="ProjectName" label="项目名称" width="80">
      </el-table-column>
      <el-table-column prop="SubProjectName" label="分项目名称" width="90">
      </el-table-column>
      <el-table-column prop="WorkingUnit" label="用工单位" width="80">
      </el-table-column>
      <el-table-column
        prop="AbbreviationName"
        label="岗位外包单位名称缩写"
        width="150"
      >
      </el-table-column>
      <!-- <el-table-column prop="PaymentPurpose" label="付款事项" width="80"> -->
      </el-table-column>
      <el-table-column
        prop="AmountWithoutVAT"
        label="发票不含VAT额"
        width="110"
      >
      </el-table-column>
      <el-table-column prop="VATAmount" label="增值税额" width="80" />
      <el-table-column prop="AmountWithVAT" label="发票含VAT额" width="100" />
      <!-- <el-table-column prop="CostCenterName" label="成本中心" width="100" /> -->
      <el-table-column prop="CostCenterCode" label="成本中心编号" width="120" />
      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <i class="fa fa-file-text-o" @click="query(scope.row)"></i>
        </template>
      </el-table-column>
    </el-table>

    <div
      class="descriptions-title"
      v-if="
        descriptions.UploadingDocument1 ||
        descriptions.UploadingDocument2 ||
        descriptions.UploadingDocument3 ||
        descriptions.UploadingDocument4 ||
        descriptions.UploadingDocument5
      "
    >
      相关附件
    </div>
    <div
      v-if="descriptions.UploadingDocument1"
      @click="download(descriptions.UploadingDocument1)"
      class="url"
    >
      {{ descriptions.UploadingDocument1 | uploadingDocumentFormater }}
    </div>
    <div
      v-if="descriptions.UploadingDocument2"
      @click="download(descriptions.UploadingDocument2)"
      class="url"
    >
      {{ descriptions.UploadingDocument2 | uploadingDocumentFormater }}
    </div>
    <div
      v-if="descriptions.UploadingDocument3"
      @click="download(descriptions.UploadingDocument3)"
      class="url"
    >
      {{ descriptions.UploadingDocument3 | uploadingDocumentFormater }}
    </div>
    <div
      v-if="descriptions.UploadingDocument4"
      @click="download(descriptions.UploadingDocument4)"
      class="url"
    >
      {{ descriptions.UploadingDocument4 | uploadingDocumentFormater }}
    </div>
    <div
      v-if="descriptions.UploadingDocument5"
      @click="download(descriptions.UploadingDocument5)"
      class="url"
    >
      {{ descriptions.UploadingDocument5 | uploadingDocumentFormater }}
    </div>
      <el-dialog
      title="当地岗位服务外包费用结算单"
      :visible.sync="dialogVisible"
      width="99vw"
      :before-close="handleClose"
         append-to-body
         class="dialog-max"
      top="1vh"
    >
      <div class="dialog-main">
        <ManpowerSupplierMonthlyCostApplication @close="handleClose" ref="manpowerSupplierMonthlyCostApplication"></ManpowerSupplierMonthlyCostApplication>
      </div>
      <div class="dialog-footer">
        <el-button class="pop-close" @click="handleClose">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getProjectAll,
  allPosition,
  getDepartmentAll,
  getCompanyAll,
} from "@/api/user";
import ManpowerSupplierMonthlyCostApplication from "@/components/tables/manpowerSupplierMonthlyCostApplication.vue";
import { getManpowerSupplierMonthlyCostApplicationDetailsItem } from "@/api/table";
export default {
  filters: {
    projectFormater(id, data) {
      const item = data.find((item) => {
        return item.ProjectID === id;
      });
      return item ? item.ProjectName : null;
    },
    positionFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.PositionsCHS : null;
    },
    departmentFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.DepartmentCHS : null;
    },
    companyFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.CompanyCHS : null;
    },
    tableDateFrom(data) {
      if (data) {
        data = data.slice(0, 10);
      }
      return data;
    },
    tableDateFrom2(data) {
      if (data) {
        data = data.slice(0, 7);
      }
      return data;
    },
    labelFormater(id, data) {
      const item = data.find((item) => {
        return item.value === id;
      });
      return item ? item.label : null;
    },
    SponsorFormater(id) {
      const data = [
        {
          label: "SNEI",
          value: 1,
        },
        {
          label: "SEG",
          value: 2,
        },
        {
          label: "其它",
          value: 3,
        },
      ];
      const item = data.find((item) => {
        return item.value === id;
      });
      return item ? item.label : null;
    },
    uploadingDocumentFormater(data) {
      const index1 = data.lastIndexOf(".");
      const index2 = data.lastIndexOf("/");
      data = data.slice(index2 + 1, index1);
      return data;
    },
  },
  data() {
    return {
      projectList: [],
      positionList: [],
      departmentList: [],
      companyList: [],
      VUE_APP_SERVER: null,
      options2: [
        {
          value: 2,
          label: "保留",
        },
        {
          value: 3,
          label: "调离",
        },
      ],
      dialogVisible: false,
      ChangeForeignRelationsofApplicantto: null,
    };
  },
  props: {
    descriptions: {
      type: Object,
    },
  },
  components: { ManpowerSupplierMonthlyCostApplication },
  methods: {
    download(data) {
      location.href = data;
    },
    handleClose() {
      this.dialogVisible = false;
      this.ChangeForeignRelationsofApplicantto = null;
    },
    query(data) {
      this.dialogVisible = true;
      getManpowerSupplierMonthlyCostApplicationDetailsItem({
        key: data.KeyID,
      }).then((res) => {
        if (res.status === 200) {
          console.log("this", res.response);
          this.$refs.manpowerSupplierMonthlyCostApplication.getEditData(
            res.response
          );
        }
      });
    },
  },
  created() {
    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
      }
    });
    allPosition().then((res) => {
      if (res.status === 200) {
        this.positionList = res.response;
      }
    });
    getCompanyAll().then((res) => {
      if (res.status === 200) {
        this.companyList = res.response;
      }
    });
    getDepartmentAll().then((res) => {
      if (res.status === 200) {
        this.departmentList = res.response;
      }
    });
    this.VUE_APP_SERVER = process.env.VUE_APP_SERVER;
  },
};
</script>
<style lang="scss" scoped>
.descriptions-title {
  font-size: 14px;
  font-weight: bolder;
  color: #333;
  margin: 12px 0;
}
.url {
  color: #0076ff;
  cursor: pointer;
  line-height: 20px;
}
.dialog-main2 {
  border-top: 1px solid #e6e6e6;
  .line-box {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #000;
    margin-top: 10px;
    padding: 0 20px;
    .name {
      flex-shrink: 0;
    }
    .el-select {
      width: 100%;
    }
  }
}
.dialog-footer {
  padding-bottom: 10px;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  // margin-right: 20px;
}
</style>
<style scoped>
.descriptions >>> .el-table__body .cell {
  color: #606266 !important;
}
.signature {
  width: 100%;
}
.fa {
  /* margin-right: 10px; */
  color: #3c8dbc;
  cursor: pointer;
  margin-left: 5px;
  cursor: pointer;
}
.text-sapn {
  font-size: 12px;
  cursor: pointer;
}
.fa:hover {
  color: #72afd2;
}
.dialog-max >>> .el-dialog__body {
  padding-bottom: 10px;
}
</style>
