<template>
  <div class="descriptions">
    <el-descriptions class="margin-top" :column="3" border>
      <el-descriptions-item
        label="Application Date 申请日期"
        label-class-name="my-label"
      >
        {{ descriptions.CreateDate | tableDateFrom }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Workflow Table S/N 表单编号"
        label-class-name="my-label"
      >
        {{ descriptions.ApplicationNumber }}
      </el-descriptions-item>
      <el-descriptions-item label="Creator 申请人" label-class-name="my-label">
        {{ descriptions.Creator }}
      </el-descriptions-item>
      <el-descriptions-item
        label="工作签证指标计划类型"
        label-class-name="my-label"
      >
        {{ descriptions.TypeName }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Working Unit 申请单位"
        label-class-name="my-label"
      >
        {{ descriptions.WorkingUnit }}
      </el-descriptions-item>
    </el-descriptions>
    <div class="descriptions-title" v-if="descriptions.Details">明细</div>

    <el-table
      :data="descriptions.Details"
      v-if="descriptions.Details"
      :header-cell-style="{ color: '#909399', background: '#f5f7fa' }"
    >
      <el-table-column type="index" label="序号" width="50"> </el-table-column>

      <el-table-column prop="UsageTime" label="使用时间" width="80">
        <template slot-scope="scope">
          {{ scope.row.UsageTime | tableDateFrom }}
        </template>
      </el-table-column>
      <el-table-column prop="NationalityName" label="国籍" width="80" />
      <el-table-column prop="JobProfessionName" label="岗位或工种" width="80" />
      <el-table-column prop="AmouttoSINOPEC" label="中石化员工" width="80" />
      <el-table-column prop="AmouttoNonSINOPEC" label="非中石化员工" width="90">
      </el-table-column>
      <el-table-column prop="WorkingPlace" label="拟工作地点" width="80">
      </el-table-column>
      <el-table-column prop="AarrivalDateatKSA" label="计划来沙时间" width="90">
        <template slot-scope="scope">
          {{ scope.row.AarrivalDateatKSA | tableDateFrom }}
        </template>
      </el-table-column>
      <el-table-column
        prop="WorkingDuration"
        label="计划在沙工作期限"
        width="120"
      >
      </el-table-column>
      <el-table-column prop="Remark" label="备注" width="80"> </el-table-column>
    </el-table>

    <div
      class="descriptions-title"
      v-if="descriptions.Attachments && descriptions.Attachments.length !== 0"
    >
      相关附件
    </div>
    <div
      v-for="item in descriptions.Attachments"
      @click="download(item.Url)"
      class="url"
      :key="item.ID"
    >
      {{ item.Name }}
    </div>
  </div>
</template>

<script>
import { getProjectAll, allPosition, getDepartmentAll } from "@/api/user";
export default {
  filters: {
    projectFormater(id, data) {
      const item = data.find((item) => {
        return item.ProjectID === id;
      });
      return item ? item.ProjectName : null;
    },
    positionFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.PositionsCHS : null;
    },
    departmentFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.DepartmentCHS : null;
    },
    tableDateFrom(data) {
      if (data) {
        data = data.slice(0, 10);
      }
      return data;
    },
  },
  data() {
    return {
      projectList: [],
      positionList: [],
      departmentList: [],
      VUE_APP_SERVER: null,
    };
  },
  props: {
    descriptions: {
      type: Object,
    },
  },
  methods: {
    download(data) {
      location.href = this.VUE_APP_SERVER + "upload/" + data;
    },
  },
  created() {
    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
      }
    });
    allPosition().then((res) => {
      if (res.status === 200) {
        this.positionList = res.response;
      }
    });

    getDepartmentAll().then((res) => {
      if (res.status === 200) {
        this.departmentList = res.response;
      }
    });
    this.VUE_APP_SERVER = process.env.VUE_APP_SERVER;
  },
};
</script>
<style lang="scss" scoped>
.descriptions-title {
  font-size: 14px;
  font-weight: bolder;
  color: #333;
  margin: 12px 0;
}
.url {
  color: #0076ff;
  cursor: pointer;
  line-height: 20px;
}
</style>
<style scoped>
.descriptions >>> .el-table__body .cell {
  color: #606266 !important;
}
.signature {
  width: 100%;
}
</style>
